<template>
  <div id="management-page" class="d-flex flex-column justify-content-between">
    <div id="management-container" class="mx-auto">
      <img
        id="app-logo"
        draggable="false"
        src="@/assets/img/icons/landing-crown.png"
        alt="Logo"
        class="to-center-abs"
      />
      <div id="app-title" class="mx-auto w-75 position-relative"></div>

      <div id="management-contents" class="w-75 mx-auto position-relative">
        <router-view />
      </div>
    </div>

    <LegalRow />
    <AlertWindow />
  </div>
</template>

<script>
import LegalRow from "@/components/legal/legal-row.vue";
import checkLocale from "@/mixins/checkLocale.js";
import AlertWindow from "@/components/base/alert-window.vue";
export default {
  name: "ManagementPage",
  components: { LegalRow, AlertWindow },
  mixins: [checkLocale],
  data() {
    return {};
  },
  mounted() {
    this.$store.commit("setIsPageLoading", false);
  },
  created: function () {
    this.checkLocale();
  },
};
</script>
<style lang="scss">
#management-page {
  padding-top: 10%;
  height: 100vh;
  #app-logo {
    height: 89.1px;
    width: 271.9px;
    z-index: 2;
    top: 0;
    @media (max-width: 600px) {
      display: none;
    }
  }
  #app-title {
    height: 48px;
    z-index: 2;
    background: url(~@/assets/img/icons/krone-logotype.png) center/100% 100%
      no-repeat;
    @media (max-width: 600px) {
      width: 200px;
      height: 85px;
      background: url(~@/assets/img/icons/krone-logotype-vert.png) center/100%
        100% no-repeat;
    }
  }

  #management-container {
    max-height: 533px;
    height: 80%;
    width: 100%;
    max-width: 620px;
    padding: 100px 0 50px 0;
    position: relative;
    background-color: transparent;
    #management-contents {
      height: 90%;
    }
    @media (max-width: 600px) {
      padding-top: 0;
    }

    // This allows for an overflowing background image
    &::before {
      content: "";
      position: absolute;
      width: 740px;
      height: 640px;
      left: -60px;
      top: -50px;
      background: url(~@/assets/img/backgrounds/login-window-back.svg)
        center/100% 100% no-repeat;
      -webkit-transform: translate3d(0, 0, 0);
      @media (max-width: 800px) {
        width: 100%;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
      @media (max-width: 600px) {
        background: none;
        height: auto;
      }
    }
  }
}
</style>
