<template>
  <div
    id="password-reset"
    class="w-100 h-100 d-flex flex-column justify-content-around"
  >
    <h1 class="m-0">{{ $t("login.password_reset") }}</h1>

    <form @submit.prevent="submitNewPassword">
      <div class="form-group m-0">
        <label class="dark-label">{{
          $t("profile.change_password.placeholder_new_password")
        }}</label>
        <input
          v-model="password"
          type="password"
          name="password"
          required
          placeholder="*******"
          class="form-control"
          :class="{ error: firstPasswordError && !minLength }"
          @blur="checkPasswordLength"
        />
        <p
          v-if="firstPasswordError && !minLength"
          class="w-100 to-center-abs small text-center text-danger"
        >
          {{ $t("profile.change_password.invalid_feedback.length", { 0: 6 }) }}
        </p>
      </div>
      <div class="form-group">
        <label class="dark-label">{{
          $t("profile.change_password.placeholder_confirm_password")
        }}</label>
        <input
          v-model="passwordConfirm"
          type="password"
          name="password"
          required
          placeholder="*******"
          class="form-control"
          :class="{ error: confirmPasswordError && !matchingPWs }"
          @blur="checkPasswordMatch"
        />
        <p
          v-if="confirmPasswordError && !matchingPWs"
          class="to-center-abs small text-center text-danger"
        >
          {{ $t("profile.change_password.invalid_feedback.match") }}
        </p>
      </div>

      <b-row id="button-row" no-gutters align-v="center" align-h="around">
        <button
          type="button"
          class="cancel-button-red"
          @click="$router.push('/')"
        >
          {{ $t("profile.change_password.cancel") }}
        </button>
        <button
          type="submit"
          class="ok-button-green"
          :class="{ disabled: !minLength || !matchingPWs }"
        >
          {{ $t("login.password_forgotten.submit") }}
        </button>
      </b-row>
    </form>
  </div>
</template>

<script>
import * as profileAPI from "@/API/profile.js";
export default {
  name: "PasswordReset",
  data() {
    return {
      password: "",
      passwordConfirm: "",
      firstPasswordError: false,
      confirmPasswordError: false,
    };
  },

  computed: {
    minLength() {
      return this.password.length >= 6;
    },
    matchingPWs() {
      return this.password === this.passwordConfirm;
    },
  },
  mounted() {
    this.$root.$on("ok-reset-success", () => {
      this.$router.push("/");
    });
  },
  methods: {
    submitNewPassword() {
      let payload = {
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.password,
        passwordConfirm: this.passwordConfirm,
      };
      profileAPI
        .resetPassword(payload)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("login.password_reset.success"),
            alertHeading: this.$t("alerts.title.success"),
            type: "check",
            dismissMessage: "ok-reset-success",
          });
        })
        .catch((e) => {
          console.log(e);
          if (e.data.id) {
            this.$store.commit("popups/setAlertBannerContent", {
              alertText: this.$t(e.data.id),
              alertHeading: this.$t("alerts.title.error"),
              type: "danger",
              variant: "danger",
            });
          } else {
            this.displayErrorMessage();
          }
        });
    },
    checkPasswordLength() {
      if (!this.minLength) {
        this.firstPasswordError = true;
      }
    },
    checkPasswordMatch() {
      if (!this.matchingPWs) {
        this.confirmPasswordError = true;
      }
    },
  },
};
</script>
