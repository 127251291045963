<template>
  <div
    id="landing-page-redirect"
    class="d-flex flex-column align-items-center justify-content-around h-100"
  >
    <template v-if="!fail">
      <h1>{{ $t("dailylogin.headline_first") }}!</h1>
      <p class="text-white text-center">
        {{ $t("welcome_page.redirect_wait") }}
      </p>
      <div style="height: 150px" class="position-relative">
        <Spinner
          size="medium"
          context="Loading Redirect..."
          classes="dead-center"
        />
      </div>
    </template>
    <template v-else>
      <h1>{{ $t("welcome_page.headline_fail") }}</h1>
      <p class="text-white text-center">
        {{ $t("welcome_page.redirect_fail") }}
      </p>
      <button class="ok-button-green" @click="$router.push('/')">
        {{ $t("login.login") }}
      </button>
    </template>
  </div>
</template>

<script>
import { getTokenForLandingPage } from "@/API/auth.js";
import Spinner from "@/components/animation/animation-spinner";
import { getSettings } from "@/API/static-config";

export default {
  name: "LandingPageRedirect",
  components: { Spinner },

  data() {
    return {
      fail: false,
      landingToken: null,
    };
  },
  created() {
    this.checkForLandingPageBonus();
    if (!this.$route.query.landingToken) {
      this.fail = true;
    } else {
      getTokenForLandingPage(this.$route.query.landingToken)
        .then((res) => {
          this.$store
            .dispatch("auth/logUserInFromLandingPage", res)
            .then(() => {
              getSettings().then((res) => {
                let gameIdFromLink = this.$store.state.gameplay.redirectGame;
                if (gameIdFromLink) {
                  this.instantSlotId = gameIdFromLink;
                } else {
                  if (res.data && res.data["newuser.instant_slot"]) {
                    this.instantSlotId = res.data["newuser.instant_slot"];

                    //save setting config in store
                    let configData = {};
                    configData.settings = res.data;
                    this.$store.commit("setGeneralConfig", configData);
                  }
                }

                //go to instant slot, if available
                if (this.instantSlotId) {
                  this.$router.push({
                    name: "game",
                    params: { gameId: this.instantSlotId },
                  });
                } else {
                  this.$router.push("/");
                }
              });
            });
        })
        .catch((e) => {
          this.fail = true;
          console.log(e);
        });
    }
  },

  methods: {
    checkForLandingPageBonus() {
      const lpName = this.$route.query.lp_redirect;
      if (lpName) {
        this.$store.commit("auth/setLpBonusName", lpName);
      }
    },
  },
};
</script>
