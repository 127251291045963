<template>
  <div
    id="email-unsubscribe"
    class="h-100 w-100 d-flex flex-column justify-content-around"
  >
    <h3 class="text-center">{{ $t("email.unsubscribe.confirmation") }}</h3>
    <b-row id="button-row">
      <b-col cols="12" sm="6" class="h-100 h-sm-50">
        <button
          class="w-100 h-100 button-blue-modern mr-3"
          @click="handleUnsubscribe"
        >
          {{ $t("yes") }}
        </button></b-col
      >
      <b-col cols="12" sm="6" class="h-100 h-sm-50">
        <button class="w-100 h-100 ok-button-green" @click="$router.push('/')">
          {{ $t("no") }}
        </button></b-col
      ></b-row
    ><AlertWindow />
  </div>
</template>
<script>
import AlertWindow from "@/components/base/alert-window.vue";
import { unsubscribeNewsletterByLink } from "@/API/profile.js";
export default {
  name: "EmailUnsubscribe",
  components: { AlertWindow },
  data() {
    return {
      hash: "",
    };
  },
  mounted() {
    this.$root.$on("ok-unsub-success", () => {
      this.$router.push("/");
    });
    this.$root.$on("ok-generic-error", () => {
      this.$router.push("/");
    });
    if (this.$route.query.hash) {
      this.hash = this.$route.query.hash;
    }
  },
  methods: {
    handleUnsubscribe() {
      unsubscribeNewsletterByLink(this.hash)
        .then((res) => {
          this.$store.commit("popups/setAlertBannerContent", {
            alertText: this.$t("profile.newsletter.unsubscribed"),
            alertHeading: this.$t("alerts.title.success"),
            type: "check",
            dismissMessage: "ok-unsub-success",
          });
        })
        .catch((e) => {
          console.log(e);
          this.displayErrorMessage();
        });
    },
  },
};
</script>
<style lang="scss">
#button-row {
  height: 50px;
  @media (max-width: 600px) {
    height: 70px;
  }
  button {
    @media (max-width: 600px) {
      font-size: 26px;
    }
  }
}
</style>
